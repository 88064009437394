<template>
  <div class="mainwrap restauranlist" v-loading="loading">
    <div class="title">
      餐廳資訊
      <div class="addbtn" v-if="permissionType === 2">
        <el-button type="success" @click="synchronizationRestaurant()">同步官網餐廳資訊</el-button>
      </div>
    </div>

    <div class="searchform">
      <el-form
        label-width="80px"
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="品牌：">
          <el-select v-model="searchForm.brandId" placeholder="请选择品牌">
            <el-option
                v-for="item in options"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分店：">
          <el-input
            v-model="searchForm.Name"
            placeholder="請輸入分店"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="name" label="分店"> </el-table-column>
        <el-table-column prop="phone" label="電話"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <!-- <el-table-column prop="address" label="美食分類"> </el-table-column> -->
        <!-- <el-table-column prop="businessHours" label="營業時間"> </el-table-column> -->
        <el-table-column prop="businessHours" label="營業時間">
          <template slot-scope="scope">{{scope.row.businessHours.slice(0,-1)}}</template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="handlelook(scope.row)" type="primary" plain>
              查看
            </el-button>
            <div class="delbtn">
              <el-popconfirm
                  confirm-button-text='確定'
                  cancel-button-text='取消'
                  confirm-button-type="danger"
                  icon="el-icon-info"
                  icon-color="red"
                  title="確定刪除？"
                  @confirm="deleteCounter(scope.row.id)"
              >
                <!-- <el-button type="danger" slot="reference">刪除</el-button> -->
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';

export default {
    name:"restaurantList",
    data(){
        return{
          loading: false,
          isSinglePage: false,
          currentPage: 1,
          tableDataTotal: 0,
          searchForm:{
            Name:"",
            brandId:"",
            sorting: '',
            maxResultCount: 10
          },
          options:[],
          tableData: [],
          permissionType: -1
        }
    },
    methods:{
      reSet() {
       this.searchForm = {
         Name:"",
         brandId:"",
         sorting: '',
         maxResultCount: 10
       }
       this.currentPage=1;
       this.getList();
      },
      //同步官網餐廳資訊
      synchronizationRestaurant(){
        this.loading=true;
        api.websiteRestaurant({}).then(res=>{
          if(res.systemCode===200){
            this.$message.success('同步成功');
            this.getList();
          }else{
            // this.$message.error(res.message);
          }
          this.loading=false;
        })
      },
      handlelook(item) {
        this.$router.push({
          path: "/cms/restaurantdetail",
          query: {
            type: "detail",
            id: item.id,
          },
        });
      },
      // 翻頁
      handleCurrentChange(val) {
        this.currentPage=val;
        this.getList()
      },
      // 查詢
      onSearch() {
        this.currentPage=1;
        this.getList();
      },
      getList() {
        this.loading = true;
        api.counterList({
          BrandId: this.searchForm.brandId,
          Name: this.searchForm.Name,
          Sorting: this.searchForm.sorting,
          SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
          MaxResultCount: this.searchForm.maxResultCount
        }).then(res=>{
          if(res.systemCode===200){
            this.tableData = res.data.items;
            this.tableDataTotal = res.data.totalCount;
          }
          this.loading = false;
        })
      },
      //獲取品牌下拉列表
      getBrandList(){
        api.counterBrandSelect({}).then(res=>{
          if(res.systemCode===200){
            this.options = res.data
          }
        })
      },
      // 刪除
      deleteCounter(id) {
        api.counterDelete(id).then(res=>{
          if(res.systemCode===200){
            this.$message.success('刪除成功');
            //如果刪除項为當前頁最后一项
            if (this.tableData.length <= 1) {
              this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
            }
            this.getList();
          }
        })
      },
    },
  created() {
    this.getList()
    this.getBrandList()
    this.permissionType = parseInt(getStore("permissionType"));
  }
};
</script>

<style lang="scss" scoped>
.restauranlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 150px;
    float: right;
  }

  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
