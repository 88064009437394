import { getRequest,deleteRequest,postRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

export const counterList=(params)=>{
    return getRequest(API.counterList,params);
}

export const counterBrandSelect=(params)=>{
    return getRequest(API.counterBrandSelect,params);
}

export const counterDetail=(params)=>{
    return getRequest(API.counterDetail+'/'+params);
}
export const counterDelete=(params)=>{
    return deleteRequest(API.counterDetail+'/'+params);
}
export const websiteRestaurant=(params)=>{
    return postRequest(API.websiteRestaurant,params);
}
export const counterEdit=(params)=>{
    return postRequest(API.counterEdit,params);
}


